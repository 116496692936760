<template>
  <div>
    <h1
      class="
        border-0
        font-semibold
        border-b border-solid border-gray-400
        py-3
        px-2
        text-blackdok text-xl
      "
    >
      Informations bancaires
    </h1>

    <div class="px-4 mt-8">
      <div class="flex flex-col lg:flex-row lg:items-center mb-5">
        <label for="prenom" class="xl:mr-5 w-1/3 font-EffraR"
          >Nom de la banque</label
        >
        <input
          type="text"
          placeholder="Nom de la banque"
          v-model="doctorProfile.bankInfo.bankName"
          class="
            bg-white
            border border-solid border-gray-300
            px-2
            py-3
            rounded-cu
            w-full
            mr-2
          "
        />
      </div>
      <div class="flex flex-col lg:flex-row lg:items-center mb-5">
        <label for="ownerName" class="xl:mr-5 w-1/3 font-EffraR"
          >Nom du titulaire du compte</label
        >
        <input
          type="text"
          placeholder="Nom du titulaire du compte"
          v-model="doctorProfile.bankInfo.ownerName"
          class="
            bg-white
            border border-solid border-gray-300
            px-2
            py-3
            rounded-cu
            w-full
            mr-2
          "
        />
      </div>
      <div class="flex flex-col lg:flex-row lg:items-center mb-5">
        <label for="prenom" class="xl:mr-5 w-1/3 font-EffraR">RIB</label>
        <div class="relative w-full pt-6">
          <div class="absolute top-0 flex flex-row font-EffraR text-gray-600">
            Banque(3) &nbsp;&nbsp; Ville(3) &nbsp;&nbsp;&nbsp;&nbsp;
            N°Compte(16) &nbsp;&nbsp;&nbsp; Clé(2)
          </div>
          <input
            type="text"
            placeholder="24 chiffres"
            v-mask="'### ### ################ ##'"
            v-model="doctorProfile.bankInfo.RIB"
            class="
              bg-white
              border border-solid border-gray-300
              px-2
              py-3
              rounded-cu
              w-full
              mr-2
            "
          />
        </div>
      </div>
      <div>
        <label for="rib" class="xl:mr-5 w-1/3 font-EffraR"
          >Télécharger le RIB</label
        >
        <a
          class="button"
          v-if="doctorProfile.bankInfo.ribFile"
          :href="$checkIcons(doctorProfile.bankInfo.ribFile, 'upload')"
          >mon RIB</a
        >
        <div class="w-full flex flex-col justify-center items-center mt-5 px-2">
          <upload-image
            :param-file.sync="doctorProfile.bankInfo.ribFile"
            :active-upload="true"
            :cropping="false"
            type="upload"
          ></upload-image>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center gap-x-5 px-2 py-2">
      <div class="flex flex-col flex-1">
        <div class="text-base font-EffraR flex items-center gap-x-2">
          Afin de recevoir les paiements des téléconsultations à la fin de
          chaque mois, prière de bien remplir les données demandées ci-desssus.
          dok.ma ne prends pas de commission, cependant, l’opérateur de paiement
          (Payzone et CMI prélèveront 2,5% en tout, répartit comme suit : 1,5%
          pour le CMI et 1% pour Payzone). Ces frais seront déduits lors du
          virement vers votre compte.
        </div>
      </div>
      <dok-button @click.native="savePayment" size="md">
        <i class="fa fa-load" v-if="btnSaving"></i>
        Sauvegarder
      </dok-button>
    </div>
  </div>
</template>

<script>
  import moment from "moment";
  const uploadImage = () => import("@/views/global-components/uploadImage.vue");

  export default {
    components: {
      uploadImage,
    },
    data() {
      return {
        btnSaving: false,
        doctorProfile: {
          bankInfo: {
            ownerName: null,
            bankName: null,
            RIB: null,
            IBAN: null,
            ribFile: null,
          },
        },
      };
    },
    async mounted() {
      let doctorProfile = (await this.$auth.getUserInfo()).doctorProfile;
      if (!doctorProfile || !doctorProfile.bankInfo) {
        doctorProfile.bankInfo = {
          ownerName: null,
          bankName: null,
          RIB: null,
          IBAN: null,
        };
      }

      this.doctorProfile = doctorProfile;
    },
    methods: {
      moment() {
        return moment;
      },
      savePayment() {
        this.btnSaving = true;
        this.$store.dispatch("doctor/SAVE_PROFILE", {
          params: {
            bankInfo: this.doctorProfile.bankInfo,
          },
          onData: this.onData,
        });
      },
      onData: function (data) {
        let _this = this;
        if (data.ok) {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 4000,
            text: data.ok ? "Mise à jour réussie" : "Mise à jour échouée",
            color: data.ok ? "success" : "danger",
            iconPack: "feather",

            icon: "icon-check",
          });
          this.$store.dispatch("user/GET_PROFILE", {
            onData: () => {
              _this.user = _this.$auth.getUserInfo();
            },
          });
        }
        this.btnSaving = false;
      },
    },
  };
</script>
